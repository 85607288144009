"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import {
  ArrowRight,
  Headset,
  Gamepad2,
  Users,
  ChevronLeft,
  ChevronRight,
  Check,
} from "lucide-react";
import { Button } from "./ui/button";
import { Card } from "./ui/card";
import { Input } from "./ui/input";

const Hero: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentReview, setCurrentReview] = useState(0);
  const [currencyCode, setCurrencyCode] = useState("USD");
  const [conversionRate, setConversionRate] = useState(1);

  const virtualClassrooms = [
  {
    name: "Quantum Physics Lab",
    image: "/assets/logo.svg",
  },
  {
    name: "Ancient History Museum",
    image: "/assets/logo.svg",
  },
  {
    name: "Molecular Biology Center",
    image: "/assets/logo.svg",
  },
];
  const reviews = [
    {
      name: "Alex Chen",
      role: "Computer Science Student",
      quote:
        "The VR physics lab made quantum mechanics click for me. It's like I can see and touch the particles!",
    },
    {
      name: "Emma Watson",
      role: "History Enthusiast",
      quote:
        "Walking through ancient Rome in VR while an AI guide explains the architecture is mind-blowing. I've never been so engaged in learning history.",
    },
    {
      name: "Dr. Rajesh Koothrappali",
      role: "Astrophysics Professor",
      quote:
        "AIStudying's immersive star mapping has revolutionized how I teach celestial mechanics. Students grasp complex concepts much faster.",
    },
    {
      name: "Sophia Rodriguez",
      role: "Medical Student",
      quote:
        "The AI-powered anatomy lessons in VR are incredible. I feel like I'm actually inside the human body!",
    },
    {
      name: "James Thompson",
      role: "Language Learner",
      quote:
        "The AI conversation partners have dramatically improved my language skills. It's like having a native speaker available 24/7.",
    },
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % virtualClassrooms.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prev) => (prev - 1 + virtualClassrooms.length) % virtualClassrooms.length
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReview((prev) => (prev + 1) % reviews.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [reviews.length]);

  useEffect(() => {
    const fetchIPBasedLocation = async () => {
      try {
        const ipResponse = await fetch(`https://ipapi.co/json/`);

        if (!ipResponse.ok) {
          throw new Error("Failed to fetch IP-based location data");
        }

        const ipData = await ipResponse.json();
        const countryCode = ipData.country;

        const countryResponse = await fetch(
          `https://restcountries.com/v3.1/alpha/${countryCode}`
        );

        if (!countryResponse.ok) {
          throw new Error("Failed to fetch country data");
        }

        const countryData = await countryResponse.json();
        const currencies = countryData[0]?.currencies;
        const fetchedCurrencyCode = currencies
          ? Object.keys(currencies)[0]
          : "USD";

        const currencyResponse = await fetch(
          `https://api.exchangerate-api.com/v4/latest/USD`
        );

        if (!currencyResponse.ok) {
          throw new Error("Failed to fetch currency data");
        }

        const currencyData = await currencyResponse.json();
        const userCurrencyRate = currencyData.rates[fetchedCurrencyCode] || 1;

        setCurrencyCode(fetchedCurrencyCode);
        setConversionRate(userCurrencyRate);
      } catch (error) {
        console.error(
          "Error fetching currency data via IP-Based Location:",
          error
        );
        setCurrencyCode("USD");
        setConversionRate(1);
      }
    };

    fetchIPBasedLocation();
  }, []);

  const formatPrice = (priceInUSD: number) => {
    const price = priceInUSD * conversionRate;
    return new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  };

  const pricingPlans = [
    {
      name: "Explorer",
      priceUSD: 9.99,
      link: "https://rzp.io/l/xeEMXD79NV",
      features: [
        "Access to basic VR classrooms",
        "Limited AI companion interactions",
        "Core gamification features",
        "24/7 customer support",
      ],
    },
    {
      name: "Innovator",
      priceUSD: 19.99,
      link: "https://rzp.io/l/xeEMXD79NV",
      features: [
        "Full access to all VR classrooms",
        "Advanced AI companion interactions",
        "Complete gamification system",
        "Personalized learning paths",
        "Priority customer support",
      ],
    },
    {
      name: "Visionary",
      priceUSD: 29.99,
      link: "https://rzp.io/l/xeEMXD79NV",
      features: [
        "Everything in Innovator plan",
        "Early access to new features",
        "Custom VR environment creation",
        "AI-powered progress analytics",
        "One-on-one virtual tutoring sessions",
      ],
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-950 to-gray-900 text-gray-100">
      <link rel="preload" href="/assets/grid.svg" as="image" />
      <section className="relative py-20 overflow-hidden">
        <div className="absolute inset-0 bg-[url('/assets/grid.svg')] bg-center opacity-5"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="flex flex-col lg:flex-row items-center gap-12">
            <div className="flex-1 text-center lg:text-left">
              <h1 className="text-5xl sm:text-6xl lg:text-7xl font-bold leading-tight mb-6 py-2">
                Immerse Yourself in
                <span className="block text-transparent bg-clip-text bg-gradient-to-r from-violet-400 to-purple-600 leading-tight py-2">
                  AI-Powered Learning
                </span>
              </h1>
              <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto lg:mx-0 leading-relaxed">
                Experience education like never before with AIStudying&apos;s
                virtual reality classrooms, gamified lessons, and collaborative
                AI-driven environments.
              </p>
              <div className="flex flex-col sm:flex-row justify-center lg:justify-start gap-4">
                <Button
                  size="lg"
                  className="bg-violet-600 hover:bg-violet-700 text-lg px-8"
                >
                  Start Your Adventure <ArrowRight className="ml-2 h-5 w-5" />
                </Button>
                <Button
                  size="lg"
                  variant="outline"
                  className="border-violet-600 text-violet-400 hover:bg-violet-600/20 text-lg px-8"
                >
                  Take a Virtual Tour
                </Button>
              </div>
            </div>
            <div className="flex-1 w-full max-w-lg">
              <div className="bg-gray-800/50 backdrop-blur-md rounded-lg p-6 shadow-lg relative overflow-hidden">
                <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-violet-400 to-purple-600"></div>
                <h3 className="text-2xl font-bold mb-4">
                  Virtual Classroom Preview
                </h3>
                <div className="relative h-64 bg-gray-700 rounded-lg mb-4 overflow-hidden">
                  <Image
                    src={virtualClassrooms[currentSlide].image}
                    alt={virtualClassrooms[currentSlide].name}
                    width={600}
                    height={400}
                    style={{ objectFit: "cover" }}
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent"></div>
                  <div className="absolute bottom-4 left-4 right-4 flex items-center justify-between">
                    <p className="text-lg font-semibold text-white">
                      {virtualClassrooms[currentSlide].name}
                    </p>
                    <div className="bg-violet-600 rounded-full p-2">
                      <Headset className="h-6 w-6" />
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <Button size="sm" onClick={prevSlide}>
                    <ChevronLeft className="h-4 w-4" />
                  </Button>
                  <p className="text-sm text-gray-400">
                    {currentSlide + 1} / {virtualClassrooms.length}
                  </p>
                  <Button size="sm" onClick={nextSlide}>
                    <ChevronRight className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 bg-gray-800/30 backdrop-blur-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-violet-400 to-purple-600 py-2 leading-relaxed">
            Revolutionizing Education Through Immersion
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                icon: Headset,
                title: "Virtual Reality Classrooms",
                description:
                  "Step into fully immersive learning environments tailored to each subject",
              },
              {
                icon: Gamepad2,
                title: "Gamified Learning",
                description:
                  "Earn points, unlock achievements, and compete with peers as you master new skills",
              },
              {
                icon: Users,
                title: "Collaborative AI Companions",
                description:
                  "Study alongside AI-powered virtual classmates that adapt to your learning style",
              },
            ].map((feature, index) => (
              <div
                key={index}
                className="bg-gray-700/30 backdrop-blur-sm border border-violet-600/20 rounded-xl p-6 hover:bg-gray-700/50 transition-all duration-300"
              >
                <feature.icon className="h-12 w-12 text-violet-400 mb-4" />
                <h3 className="text-xl font-semibold mb-2 text-white">
                  {feature.title}
                </h3>
                <p className="text-gray-300 leading-relaxed">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-violet-400 to-purple-600 py-4 leading-normal">
            <span className="inline-block">Choose Your Learning Adventure</span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {pricingPlans.map((plan, index) => (
              <Card
                key={index}
                className={`relative bg-gray-800/50 backdrop-blur-md border-violet-600/20 hover:bg-gray-800/70 transition-all duration-300 flex flex-col ${
                  plan.name === "Innovator"
                    ? "transform scale-105 shadow-xl"
                    : ""
                }`}
              >
                {plan.name === "Innovator" && (
                  <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gradient-to-r from-violet-500 to-purple-600 text-white px-4 py-1 rounded-full text-sm font-semibold">
                    Recommended
                  </div>
                )}
                <div
                  className={`p-6 flex flex-col flex-grow ${
                    plan.name === "Innovator"
                      ? "border-2 border-violet-500 rounded-lg"
                      : ""
                  }`}
                >
                  <h3 className="text-2xl font-bold mb-2 text-white">
                    {plan.name}
                  </h3>
                  <p className="text-3xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-violet-400 to-purple-600">
                    {formatPrice(plan.priceUSD)}
                    <span className="text-lg font-normal text-gray-400">
                      /month
                    </span>
                  </p>
                  <ul className="space-y-2 mb-6 flex-grow">
                    {plan.features.map((feature, fIndex) => (
                      <li
                        key={fIndex}
                        className="flex items-center text-gray-300 leading-relaxed"
                      >
                        <Check
                          className={`h-5 w-5 mr-2 flex-shrink-0 ${
                            plan.name === "Innovator"
                              ? "text-violet-500"
                              : "text-violet-400"
                          }`}
                        />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                  <a
                    href={plan.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full mt-auto"
                  >
                    <Button
                      className={`w-full ${
                        plan.name === "Innovator"
                          ? "bg-gradient-to-r from-violet-500 to-purple-600 hover:from-violet-600 hover:to-purple-700"
                          : "bg-violet-600 hover:bg-violet-700"
                      }`}
                    >
                      Choose Plan
                    </Button>
                  </a>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Revolving Reviews Section */}
      <section className="py-20 bg-gray-800/30 backdrop-blur-md overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-violet-400 to-purple-600 py-4 leading-normal">
            <span className="inline-block">What Our Learners Say</span>
          </h2>
          <div className="relative h-64">
            {reviews.map((review, index) => (
              <div
                key={index}
                className="absolute w-full transition-all duration-500 ease-in-out"
                style={{
                  transform: `translateX(${(index - currentReview) * 100}%)`,
                  opacity: index === currentReview ? 1 : 0,
                  visibility: index === currentReview ? "visible" : "hidden",
                }}
              >
                <Card className="bg-gray-700/30 backdrop-blur-sm border border-violet-600/20 p-6">
                  <p className="text-lg mb-4 text-gray-200 italic leading-relaxed">
                    &quot;{review.quote}&quot;
                  </p>
                  <p className="font-semibold text-white">{review.name}</p>
                  <p className="text-violet-400">{review.role}</p>
                </Card>
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-4">
            {reviews.map((_, index) => (
              <button
                key={index}
                className={`h-2 w-2 rounded-full mx-1 ${
                  index === currentReview ? "bg-violet-600" : "bg-gray-600"
                }`}
                onClick={() => setCurrentReview(index)}
              />
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-r from-violet-900/50 to-purple-900/50 backdrop-blur-md">
        <div className="max-w-4xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-violet-400 to-purple-600 py-4 leading-normal">
            <span className="inline-block">
              Ready to Transform Your Learning Journey?
            </span>
          </h2>
          <p className="text-xl text-gray-300 mb-8 leading-relaxed">
            Join thousands of learners experiencing the future of education
            through immersive AI-powered studying.
          </p>
          <div className="flex flex-col sm:flex-row justify-center items-center gap-4">
            <Input
              type="email"
              placeholder="Enter your email"
              className="max-w-xs"
            />
            <Button
              size="lg"
              className="bg-violet-600 hover:bg-violet-700 text-lg px-8"
            >
              Start Free Trial <ArrowRight className="ml-2 h-5 w-5" />
            </Button>
          </div>
          <p className="mt-4 text-sm text-gray-400 leading-relaxed">
            No credit card required. 7-day free trial with full access to VR
            classrooms and AI companions.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Hero;
